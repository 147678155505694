import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Navigation, EffectCoverflow, Autoplay, Pagination } from 'swiper/modules';
import SwiperCore from 'swiper'
import 'swiper/swiper-bundle.css'
import { CiViewTimeline } from "react-icons/ci";
import { IoIosTimer } from "react-icons/io";

import PROGRAMS from "../../../../Assets/constants/programs.json";

import "./ProgramCarousel.css"
import { useState } from 'react';
import Brochure from '../../../../pages/demo/CTA_Modal/download_brochure';
import Moment from "react-moment";
import moment from "moment";

const date = moment().day("Sunday").add(7, "days");

SwiperCore.use([EffectCoverflow, Navigation, Autoplay])


const ProgramCarousel = ({ vertical }) => {

  const verticalPrograms = PROGRAMS.filter(program => program.category.toLocaleLowerCase() === vertical.toLocaleLowerCase());

  const [brotureDownloadInfo, setBrotureDownloadInfo] = useState(null)
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (program) => {
    setBrotureDownloadInfo(program)
    setOpen(true)
  }

  return (
    <>
      {verticalPrograms.length > 0 && (
        <div className='w-full mt-4 relative'>
          <div className='mt-2 w-full flex items-center px-[10px]'>
            <h1 className='text-xl md:text-[24px] capitalize font-semibold'>{vertical}</h1>
            {/* <Link to="/filters" className='px-3 md:px-4 py-1 md:py-2 bg-universal/5 rounded-md md:text-base text-sm text-nowrap font-semibold text-universal hover:text-darkBlue hover:bg-blue-200/70 cursor-pointer z-10 text-center'>
              View All
            </Link> */}
          </div>
          {/* <div className='max-w-lg w-full'>
            <img className='w-full' src="/Rcimages/data-science.png" alt="data science" />
          </div> */}
          {/* <div className='w-full max-w-4xl'> */}
          <Swiper
            spaceBetween={40}
            breakpoints={{
              1280: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              0: {
                slidesPerView: 1,
                spaceBetween: 10,
                navigation: false
              }
            }}
            watchOverflow={true}
            navigation
            watchSlidesProgress={true}
            modules={[Navigation, Pagination]}
            className="mySwiper2 py-2"
          >
            {verticalPrograms.map((val, index) => (
              <SwiperSlide key={index} className='pb-6'>
                <div className='w-full md:h-[470px] rounded-2xl overflow-hidden bg-white drop-shadow flex flex-col relative'>
                  {val.status && (
                    <div className='absolute z-40 w-full flex items-center justify-end px-8 text-white'>
                      <div className='rounded-b-lg bg-green-500 px-4 py-1'>
                        <h1 className='font-medium capitalize drop-shadow-md text-white'>{val.status}</h1>
                      </div>
                    </div>
                  )}
                  <div className='w-full h-[240px] overflow-hidden relative'>
                    <div className='program-cover-grad' />
                    <img src={val.cover_image} className='w-full h-full object-cover' alt="" />
                    <div className='w-full absolute z-10 left-0 bottom-0 bg-white right-0 rounded-tl-[20px]'>
                      <svg className="card__arc" xmlns="http://www.w3.org/2000/svg"><path /></svg>
                      <div className='w-full pt-8 pb-2 flex items-center px-4 gap-4'>
                        <div className='w-12 h-12 md:w-16 md:h-16 flex-shrink-0 rounded-md overflow-hidden p-2 bg-white drop-shadow-md'>
                          <img src={val.university_image} className='w-full h-full object-contain rounded-sm' alt="" />
                        </div>
                        <h1 className='text-start md:text-center font-medium md:text-base text-sm cursor-context-menu'>
                          {val.university_name}
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div className='w-full flex-1 flex flex-col justify-between px-4 py-4'>
                    <h1 className='text-base md:text-lg font-medium mt-3 cursor-context-menu'>{val.program_name}</h1>
                    <div className='w-full md:mt-0 mt-4'>
                      <div className='flex items-center gap-2'>
                        <CiViewTimeline className='text-xl md:text-2xl text-universal/90' strokeWidth={0.5} />
                        <h1 className='text-sm md:text-base font-medium capitalize cursor-context-menu'>{val.duration} - <span>{val.batch_type}</span></h1>
                      </div>
                      <div className='flex items-center gap-2 mt-2'>
                        <IoIosTimer className='text-xl md:text-2xl text-universal/90' strokeWidth={0.5} />
                        <h1 className='text-sm md:text-base font-medium capitalize cursor-context-menu'>
                          Enroll Till :{" "}
                          {val.deadline ? (
                            val.deadline
                          ) : (
                            <Moment format="D MMM YYYY">
                              {date}
                            </Moment>
                          )}
                        </h1>
                      </div>
                    </div>
                    <div className='flex items-center w-full gap-2 md:mt-0 mt-4'>
                      <a href={`/programs/${val.id}`} className='px-6 py-2.5 flex-1 text-sm text-nowrap md:text-base md:font-semibold border border-neutral-300 rounded-lg text-center'>
                        View Program
                      </a>
                      <button className='px-6 py-2.5 flex-1 text-sm text-nowrap md:text-base md:font-semibold bg-universal text-white rounded-lg' onClick={() => handleOpen(val)}>
                        Get Brochure
                      </button>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          {/* </div> */}
        </div>
      )}
      {brotureDownloadInfo && (
        <Brochure
          open={open}
          handleClose={handleClose}
          source={brotureDownloadInfo.brochure.source}
          program={brotureDownloadInfo.category}
          campaign_name={brotureDownloadInfo.brochure.campaign_name}
          pdf={brotureDownloadInfo.brochure.pdf}
          image={brotureDownloadInfo.brochureHeader}
        />
      )}
    </>
  )
}

export default ProgramCarousel

